@import "../../App.scss";
.about__register__container {
    .about__register__img {
        display: flex;
        flex-direction: column;
        background-image: url($base-url + "/associationGuide.jpg");
        color: $base-text-color;
        align-items: center;
        justify-content: end;
        border-radius: 0 0 20px 20px;
        padding: $xxs-padding 0;
        height: $xxl-heigth;
        background-size: cover;
        h3 {
            font-family: $secondary-font;
            font-style: italic;
            font-size: $xxxl-font-size;
        }
    }
    .about__register {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        h3 {
            font-family: $secondary-font;
            font-size: $lg-font-size;
            font-style: italic;
        }
        p,
        button {
            max-width: $xxl-width;
            width: 100%;
            font-family: $extra-font;
            font-size: $s-font-size;
        }
    }
}

@media screen and (min-width: 800px) {
    .about__register__container {
        .about__wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
            .about__register {
                flex-direction: row;
                gap: 40px;
                padding: $lg-margin 0;
                max-width: 1000px;
                .MuiDivider-root {
                    border-right-width: thin;
                    align-self: stretch;
                    width: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .about__register__container {
        .about__wrapper {
            .about__register {
                gap: 60px;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    .about__register__container {
        .about__wrapper {
            .about__register {
                gap: 120px;
            }
        }
    }
}
