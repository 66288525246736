@import "../../App.scss";
.reviews__container {
    display: flex;
    flex-direction: column;
    background-color: $base-background-color;
    margin: $md-margin $non-margin;
    color: $base-text-color;
    text-align: center;
    border-radius: 0 0 20px 20px;
    align-items: center;
    h3 {
        font-family: $extra-font;
        font-weight: 800;
        font-style: italic;
        font-size: $xl-font-size;
        text-align: center;
    }
    .reviews {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-bottom: 50px;
        .review {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .star__review {
                flex-direction: row;
            }
            p {
                margin: 0;
            }
        }
        .MuiDivider-root {
            width: $xl-width;
            align-self: center;
            border-color: $secondary-background-color;
            margin-bottom: $md-margin;
            border-style: dotted;
        }
        p {
            font-family: $secondary-font;
            font-size: $xs-font-size;
            font-weight: 500;
            font-style: italic;
            padding: $non-padding $md-padding;
            max-width: $xl-width;
        }
    }
    button {
        border: 1px solid $base-text-color;
        width: $md-width;
    }
}

@media screen and (min-width: 600px) {
    .reviews__container {
        .reviews {
            flex-direction: row;
            p {
                max-width: $lg-width;
            }
            .MuiDivider-root {
                border-bottom-width: 0;
                border-right-width: thin;
                align-self: stretch;
                width: 0;
                margin-bottom: 0;
            }
        }
    }
}
