@import "../../App.scss";
.productadmin__prices {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    position: relative;
    svg {
        cursor: pointer;
    }
    p {
        position: absolute;
        color: red;
        top: 30px;
    }
}