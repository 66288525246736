@import "../../App.scss";
.checkout__container {
    width: 100%;
    .checkout__stepper {
        padding: $s-padding;
        max-width: 800px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 800px) {
    .checkout__container {
        flex-direction: row;
        gap: 30px;
        justify-content: center;
        align-items: start;
        .checkout__info {
            max-width: 600px;
            width: 100%;
            .checkout__resume {
                justify-content: start;
                gap: 20px;
                .checkout__img {
                    border: 1px solid $gray-border-color;
                    padding: 10px;
                }
                .detail__product {
                    align-self: start;
                    width: 100%;
                    h3 {
                        font-size: $md-font-size;
                        margin: 0;
                    }
                    .detail__pack {
                        margin: $xxxs-padding 0;
                        font-family: $extra-font;
                        font-weight: 500;
                    }
                }
                .detail__total {
                    width: 100%;
                    .total__price {
                        font-size: $md-font-size;
                        margin: 0;
                    }
                }
            }
        }
    }
}
