@import "../../App.scss";
.breadcrumb__container {
    padding: $xxs-padding $xxxs-padding;
    color: $black-text-color;
    display: flex;
    align-items: center;
    line-height: 5px;
    svg {
        height: $xs-font-size
    }
    p {
        font-family: $extra-font;
        font-size: $xs-font-size;
    }
}