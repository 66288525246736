@import "../../App.scss";
.selections_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .MuiSkeleton-root {
        margin-bottom: 80px;
    }
    h3,
    p {
        margin: 0;
    }
    .MuiDivider-root {
        margin: $xxs-padding;
    }
    .selection__title {
        margin: $xs-margin 0;
    }
    .selections_img {
        display: flex;
        flex-direction: column;
        background-image: url($base-url + "/selections.jpg");
        color: $base-text-color;
        align-items: center;
        justify-content: end;
        border-radius: 0 0 20px 20px;
        padding: $xxs-padding 0;
        height: $md-heigth;
        background-size: cover;
        width: 100%;
        h3 {
            margin: 0;
            font-family: $secondary-font;
            font-style: italic;
            font-size: $xxl-font-size;
            font-weight: 800;
        }
    }
    .selections__shipment {
        background-color: $secondary-background-color;
        padding: $lg-padding 0 0 0;
        width: 100%;
    }
    .selections {
        overflow: hidden;
        width: 100%;
        margin-top: 120px;
        .selections__card {
            .product__container {
                .product__wrapper {
                    max-width: $xxl-width;
                }
            }
        }
    }
}

@media screen and (min-width: 800px) {
    .selections_container {
        .selections_img {
            height: $xxl-heigth;
        }
        .selections {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: $xxl-margin;
            justify-content: space-around;
            .selections__card {
                .product__container {
                    max-width: $xxl-width;
                }
            }
        }
    }
}
