@import "../../../App.scss";
.approved__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: $xxl-padding;
    width: 100%;
    .approved__info {
        width: 100%;
        text-align: left;
        margin: $xs-margin 0 $xxxs-padding $xs-margin;
        color:#2e7d32;
        font-size: $xxs-font-size;
    }
    .approved__payment {
        font-style: normal;
        border: 1px solid #2e7d32;
        border-radius: 5px;
        padding: 0 $xxxs-padding;
        margin: 0 $xxs-margin;
        max-width: $xxl-width;
        width: 100%;
        .MuiPaper-root {
            background-color: white;
            padding: 0;
            padding-top: $xxxs-padding;
            color: $black-text-color;
            font-weight: 800;
        }
        p {
            margin: 0;
            font-size: $xs-font-size;
        }
        button {
            width: 100%;
        }
    }
}
