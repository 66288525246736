@import "../../App.scss";
.carousel-root {
    .carousel {
        .control-dots {
            margin-bottom: -50px;
            display: none;
            .dot {
                background: #000;
            }
        }
        .slider-wrapper {
            .slider {
                .slide {
                    display: flex;
                    flex-direction: row;
                    flex-flow: row;
                    gap: 100px;
                    justify-content: center;
                    img {
                        width: $xl-width;   
                    }
                }
            }
        }
        &.carousel-slider {
            .control-arrow {
                margin-top: 150px;
                width: 40px;
                background-color: black;
            }
        }
        .carousel-status {
            display: none;
        }
    }
}