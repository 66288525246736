@import "../../App.scss";
.productadmin {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    .productadmin__wrapper {
        padding: $md-padding;
        display: flex;
        flex-direction: column;
        gap: 40px;
        justify-content: center;
        align-items: flex-start;
        .product__pack {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 30px;
        }
        .productadmin__form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 30px;
            .MuiOutlinedInput-root {
                &.Mui-focused .MuiOutlinedInput-notchedOutline {
                    border-color: #86b7fe;
                    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
                    border-width: 1px;
                }
            }
            .input__image::before:focus {
                border-color: #86b7fe;
                box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
                border-bottom-width: 1px;
            }
        }
        .packadmin__wrapper {
            .packadmin__types {
                display: flex;
                align-items: center;
                gap: 20px;
                width: 100%;
                #quantity-input {
                    width: 20%;
                }
            }
        }
        .productadmin__packs {
            padding: 40px 0px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .MuiStack-root {
                background-color: rgb(209, 191, 191);
                padding: 8px;
                display: flex;
                flex-direction: row;
                justify-content: start;
                gap: 30px;
                align-items: center;
                .MuiAvatar-root {
                    background-color: rgb(77, 12, 12);
                }
                .pack__icon {
                    width: 100%;
                    display: flex;
                    justify-content: end;
                    svg {
                        color: rgb(92, 27, 27);
                        cursor: pointer;
                    }
                }
            }
        }
        .MuiChip-root {
            background-color: $base-background-color;
            color: $base-text-color;
            max-width: 180px;
            .MuiAvatar-root {
                margin: 0;
            }
            svg {
                color: $base-text-color;
                margin: 0;
            }
        }
    }
    .productadmin__save {
        position: relative;
        button {
            width: 320px;
            &.disabled {
                background-color: gray;
            }
        }
        .MuiCircularProgress-root {
            position: absolute;
            top: 32px;
            left: 144px;
            color: $base-background-color;
        }
    }
}

@media screen and (min-width: 800px) {
    .productadmin {
        .productadmin__wrapper {
            flex-direction: row;
            .productadmin__form {
                .productadmin__prices {
                    flex-direction: row;
                    align-items: center;
                    .MuiFormControl-root {
                        max-width: 150px;
                        &:first-of-type {
                            max-width: 120px;
                        }
                    }
                }
            }
        }
    }
}
