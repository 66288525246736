@import "../../App.scss";
.footer__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: $extra-font;
    font-size: $xs-font-size;
    align-items: center;
    width: 100%;
    .MuiDivider-root {
        width: 80%;
        align-self: center;
    }
    h3 {
        color: $gray-text-color;
    }
    button {
        background-color: $extra-button-color;
    }
    .footer__subscription {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: $xs-margin;
        max-width: $xxl-width;
        button {
            width: 300px;
        }
        p {
            align-self: start;
            margin: 0;
            padding-top: 10px;

            font-size: $s-font-size;
            color: $extra2-text-color;
        }
    }
    .footer {
        .footer__texts {
            display: flex;
            align-items: start;
            justify-content: start;
            gap: 120px;
            line-height: 10px;
            color: $extra2-text-color;
            margin-bottom: $s-margin;
            color: $gray-text-color;
        }
        .footer__info {
            display: flex;
            flex-direction: column;
            justify-content: start;
            .footer__cancel {
                align-self: center;
            }
            .footer__social {
                display: flex;
                gap: 15px;
                justify-content: center;
            }
            .footer_contact {
                align-self: center;
                text-align: center;
                color: $secondary-text-color;
                font-weight: 800;
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .footer__container {
        .footer__subs__container {
            display: flex;
            justify-content: center;
            gap: 60px;
            width: 100%;
            margin-top: $xs-margin;
            .subscription__text {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: $xxl-width;
                align-self: start;
                h3,
                p {
                    margin: 0;
                    text-align: center;
                }
                h3 {
                    font-size: $xl-font-size;
                    font-style: italic;
                }
                p {
                    max-width: 350px;
                    align-self: center;
                    font-size: $xs-font-size;
                }
            }
            .footer__subscription {
                width: 100%;
                margin-top: 0;
            }
        }
        .footer {
            display: flex;
            gap: 80px;
            justify-content: center;
            .footer__info {
                h3 {
                    margin-top: 10px;
                }
            }
        }
        .footer__cancel {
            align-self: center;
            button {
                margin-top: 0;
            }
        }
    }
}
