@import "../../App.scss";
.button__container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    button {
        width: 320px;
        &.disabled {
            background-color: gray;
        }
    }
    .MuiCircularProgress-root {
        position: absolute;
        top: 32px;
        left: 550px;
        color: $base-background-color;
    }
}