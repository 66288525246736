@import "../../App.scss";
.showbooking__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .showbooking__link {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        max-width: $xxl-width;
        color: $gray-text-color;
        p {
            margin: $xxxs-padding 0;
        }
    }
    .MuiDivider-root {
        width: 100%;
        max-width: $xxl-width;
    }
}
