@import "../../App.scss";
.packadmin__wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    h3 {
        margin: 0;
    }
    // .packadmin__desc {
    //     .MuiTextField-root {
    //         .MuiInputBase-root {
    //             height: 115px;
    //             padding-top: $s-padding;
    //         }
    //     }
    // }
    .packform__add {
        background-color: $secondary-background-color;
        color: $black-text-color;
        border: solid 1px $base-border-color;
        width: 120px;
        align-self: center;
        margin: 0;
    }
}
