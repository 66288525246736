@import "../../App.scss";
.login__container {
    margin: 150px 0 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    button {
        margin: 0 auto;
        max-width: 300px;
    }
    a {
        cursor: pointer;
        color: $gray-text-color;
        font-size: $xs-font-size;
        text-decoration: underline;
    }
}
