@import "../../App.scss";
.contact__container {
    .contact__img {
        display: flex;
        flex-direction: column;
        background-image: url($base-url + "/contact.jpg");
        color: $base-text-color;
        align-items: center;
        justify-content: end;
        border-radius: 0 0 20px 20px;
        height: $md-heigth;
        background-size: cover;
        width: 100%;
        h3 {
            font-family: $secondary-font;
            font-style: italic;
            font-size: $xxl-font-size;
        }
    }
    .contact__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: $xxl-padding;
        width: 100%;
        gap: 20px;
        .MuiFormControl-root {
            max-width: $xxl-width;
        }
        .contact__notes {
            max-width: $xxl-width;
            width: 100%;
            .MuiTextField-root {
                .MuiInputBase-root {
                    height: 115px;
                    padding-top: $s-padding;
                }
            }
        }
        button {
            width: 100%;
            max-width: $xxl-width;
        }
    }
}

@media screen and (min-width: 800px) {
    .contact__container {
        .contact__img {
            height: $xxl-heigth;
        }
        .contact__form {
            .MuiFormControl-root {
                max-width: 600px;
            }
            .contact__notes {
                max-width: 600px;
                width: 100%;
            }
            button {
                max-width: 600px;
            }
        }
    }
}
