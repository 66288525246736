@import "../../App.scss";
.checkout__resume__total {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $xxl-width;
    h3 {
        font-family: $extra-font;
        font-weight: 700;
        font-size: $lg-font-size;
        margin: $xs-margin 0 $xxs-margin 0;
    }
    .resume__price {
        padding: $xxxs-padding 0 $xl-padding 0;
        .detail__price {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 22px;
            font-weight: 500;
        }
    }
    button {
        margin: $xs-margin 0;
    }
    .resume__links {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        a {
            color: $gray-text-color;
        }
    }
    .total__price {
        margin: $xxxs-padding 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 22px;
        font-weight: 500;
    }
}
