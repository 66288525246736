@import "../../App.scss";
.container__header {
    padding: 0 20px;
    background-color: $base-background-color;
    color: $base-text-color;
    &.header__fixed {
        position: fixed;
        top: 0; 
        left: 0;
        right: 0;
        z-index: 1000; 
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    .header__icons {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}


@media screen and (min-width: 800px) {
    .container__header {
        .header__icons {
            align-items: end;  
            .header__desktop {
                display: flex;
                align-items: center;
                img {
                    align-self: flex-start;
                }
            }
        }
    }
}

