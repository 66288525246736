@import "../../App.scss";
.locality__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .save__button {
        button {
            width: 150px;
        }        
    }
}
