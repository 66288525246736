@import "../../App.scss";
.register__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .register__img {
        display: flex;
        flex-direction: column;
        background-image: url($base-url + "/association.jpg");
        color: $base-text-color;
        align-items: center;
        justify-content: end;
        border-radius: 0 0 20px 20px;
        padding: $xxs-padding 0;
        height: $xxl-heigth;
        background-size: cover;
        .register__title {
            font-family: $secondary-font;
            font-weight: 800;
            font-size: $xxxl-font-size;
            font-style: italic;
            margin: 0;
        }
        .register__subtitle {
            font-family: $extra-font;
            font-size: $xl-font-size;
            font-weight: 300;
            margin: 0;
        }
    }
    .register__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: $xl-padding 0 $md-padding 0;
        h3 {
            font-family: $secondary-font;
            font-size: $lg-font-size;
            font-style: italic;
        }
        .MuiFormControl-root {
            max-width: $xxl-width;
            font-family: $extra-font;
            font-size: $s-font-size;
            margin-bottom: $xxs-margin;
        }
    }
    .register__payment {
        align-self: center;
        width: 100%;
        max-width: $xxl-width;
        margin-bottom: $md-margin;
        h3 {
            text-align: center;
            margin-top: 0;
            font-family: $secondary-font;
            font-size: $lg-font-size;
            font-style: italic;
        }
        img {
            width: $md-width;
        }
    }
    .button__container {
        margin-bottom: $lg-margin;
        button {
            font-family: $extra-font;
            font-size: $s-font-size;
        }
    }
    .MuiDivider-root {
        width: 80%;
        align-self: center;
    }
}

@media screen and (min-width: 800px) {
    .register__container {
        .register__form {
            .MuiFormControl-root {
                max-width: 600px;
            }
        }
        .register__payment {
            max-width: 600px;
        }
    }
}
