@import "../../App.scss";
nav {
    &.nav__fixed {
        position: fixed;
        top: 0;
    }
    .nav__container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: inherit;
        margin: auto;
        color: var(--color-primary);
        z-index: 100;
        transition: 0.5s;
        .nav__links {
            display: flex;
            flex-direction: column;
            position: absolute;
            height: 100vh;
            width: 0vw;
            top: 0;
            left: 0;
            z-index: 100;
            transition: 0.5s;
            overflow: hidden;
            background: rgba(21, 24, 33, 1);
            cursor: pointer;
            img {
                width: 100px;
                align-self: center;
                margin-bottom: $s-margin;
            }
            .nav__icons {
                position: absolute;
                top: 20px;
                right: 20px;
            }
            .MuiDivider-root {
                width: 350px;
                border-color: $secondary-background-color;
                margin: 5px 20px;
            }
            .nav__link {
                display: flex;
                align-items: center;
                justify-content: space-between;
                line-height: 30px;
                transition: 0.5s;
                padding: 0 20px;
                font-family: $secondary-font;
                letter-spacing: 2px;
                font-size: $xs-font-size;
                max-width: 350px;
                svg {
                    height: 14px;
                }
                img {
                    height: 30px;
                    width: 30px;
                    margin-bottom: 0;
                }
                &.login {
                    justify-content: start;
                    margin-top: $xxl-margin;
                    gap: 10px;
                }
            }
            &.nav__visible {
                width: 100vw;
            }
        }
        .nav__shop {
            position: relative;
        }
        .nav__user {
            position: relative;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
            background: rgba(0, 0, 0, 0.7);
            .user__container {
                position: absolute;
                right: 0;
                top: 30px;
                width: 280px;
                background: rgba(0, 0, 0, 0.87);
                box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
                border-radius: 20px;
                .user__options {
                    display: flex;
                    flex-direction: column;
                    padding: 0 20px;
                    margin-top: 20px;
                    .user__option {
                        height: 60px;
                        transition: 0.5s;
                        td:first-child {
                            width: 40px;
                        }
                        .user__name {
                            font-size: 14px;
                            font-weight: 800;
                        }
                        .user__mail {
                            font-size: 12px;
                        }
                        .user__picture {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                        }
                    }
                    a {
                        text-decoration: none;
                        color: white;
                        font-size: 18px;
                    }
                }
            }
        }
        .nav__icons {
            .nav__bullet {
                position: absolute;
                right: 27px;
                top: 15px;
                height: 35px;
                width: 35px;
                border-radius: 50%;
                position: relative;
                display: inline-block;
                font-weight: 800;
                font-size: 22px;
                border: 4px solid var(--color-select);
                position: relative;
                transition: 0.2s;
                span {
                    position: absolute;
                    left: 30%;
                }
            }
            i,
            .user__picture {
                color: var(--color-primary);
                margin: 0 var(--margin-xs);
                cursor: pointer;
                transition: 0.5s;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                &:hover {
                    transform: scale(1.5);
                }
            }
        }
        .icon {
            display: block;
            cursor: pointer;
            //padding: 23.5px 20px;
        }
    }
}

.navbar__container {
    display: flex;
    align-items: center;
    gap: 30px;
    padding-bottom: $xxxs-padding;
    width: 100%;
    max-width: 600px;
    justify-content: end;
    margin-right: $xs-margin;
    .navbar__link {
        cursor: pointer;
    }
}
