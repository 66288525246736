@import "../../App.scss";
.MuiCircularProgress-root {
    align-self: center;
}
.wines__container {
    display: flex;
    flex-direction: column;
    .MuiSkeleton-root {
        align-self: center;
    }
    h3 {
        font-family: $extra-font;
        font-weight: 800;
        font-style: italic;
        color: $secondary-text-color;
        font-size: $xl-font-size;
        text-align: center;
    }
    .wines__selection {
        display: flex;
        flex-direction: column;
        padding: $xs-padding;
        align-items: center;
        .MuiDivider-root {
            width: $xxl-width;
            align-self: center;
        }
        .wines__selector {
            padding: 10px;
            overflow: auto;
            white-space: nowrap;
            margin: $non-margin $xs-padding;
            max-width: 340px;
            .wine__selector {
                line-height: 40px;
                width: $xs-width;
                height: $xxs-heigth;
                text-align: center;
                display: inline-block;
                &.current__selector {
                    border-radius: 20px;
                    background: $base-background-color;
                    box-shadow:
                        0px 4px 8px 3px rgba(0, 0, 0, 0.2),
                        0px 1px 3px rgba(0, 0, 0, 0.1);
                    color: $base-text-color;
                }
            }
        }
        .image__container {
            position: relative;
            overflow: hidden;
            img {
                width: $xxl-width;
                padding: $md-padding $non-padding;
            }
            .images {
                opacity: 0;
                transition: opacity 0.3s ease;
                position: absolute;
                left: 0;
                top: 0;
                &.current__image {
                    opacity: 1;
                    position: relative;
                }
            }
        }
        .MuiDivider-root {
            width: 100%;
            max-width: $xxl-width;
        }
    }
    .wine__subtitle {
        font-family: $extra-font;
        font-style: normal;
        font-size: $lg-font-size;
        font-weight: 500;
    }
    button {
        align-self: center;
        width: $xxl-width;
        margin: 0;
    }
}

@media screen and (min-width: 600px) {
    .wines__container {
        .wines__selection {
            padding: $xs-padding $xs-padding 0 $xs-padding;
            .wine__container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                gap: 100px;
                .arrow {
                    color: $base-text-color;
                    &.active {
                        display: block;
                        color: $secondary-text-color;
                        cursor: pointer;
                    }
                }
                .carousel-root {
                    .carousel {
                        .control-dots {
                            margin-bottom: -50px;
                            display: none;
                            .dot {
                                background: #000;
                            }
                        }
                        .slider-wrapper {
                            .slider {
                                .slide {
                                    display: flex;
                                    flex-direction: row;
                                    flex-flow: row;
                                    gap: 100px;
                                    justify-content: center;
                                }
                            }
                        }
                        &.carousel-slider {
                            .control-arrow {
                                margin-top: 150px;
                                width: 40px;
                                background-color: black;
                            }
                        }
                        .carousel-status {
                            display: none;
                        }
                    }
                }
                .wine__card {
                    display: flex;
                    flex-direction: column;
                    max-width: $lg-width;
                    height: 380px;
                    gap: 20px;
                    .wine__month {
                        background-color: $base-background-color;
                        width: 91px;
                        height: 34px;
                        color: $base-text-color;
                        text-align: center;
                        line-height: 32px;
                        font-size: $xs-font-size;
                    }
                    .wine__image {
                        display: flex;
                        height: $xl-heigth;
                        align-self: center;
                        img {
                            width: $md-width;
                            align-self: center;
                        }
                    }
                    .wine__detail {
                        text-align: center;
                        margin-bottom: $xs-margin;

                        font-weight: 500;
                        p {
                            margin: 0;
                        }
                    }
                    button {
                        max-width: $lg-width;
                    }
                }
            }
            .wine__selector {
                margin-top: $xxs-margin;
                display: flex;
                align-items: center;
                gap: 5px;
                .selector {
                    background-color: $extra-background-color;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    cursor: pointer;
                    &.active {
                        background-color: $base-background-color;
                    }
                }
            }
        }
    }
}
