@import "../../App.scss";
.form__container {
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 30px;
    h3 {
        font-family: $secondary-font;
        font-style: italic;
        font-size: $s-font-size;
        font-weight: 800;
    }
    .MuiFormControl-root {
        margin-bottom: $xxs-margin;
        .MuiFormLabel-root {
            font-size: $xxs-font-size;
            line-height: 22px;
            color: $gray-text-color;
        }
        .MuiFormHelperText-root {
            font-size: 10px;
        }
        .MuiInputBase-root {
            // .MuiAutocomplete-endAdornment {
            //     top: 0;
            // }
            textarea {
                height: 40px;
            }
        }
    }
    .MuiAutocomplete-root {
        .MuiFormControl-root {
            .MuiInputBase-root {
                .MuiInputBase-input {
                    padding-top: 0;
                }
            }
        }
    }
    .formassoc__location {
        display: flex;
        justify-content: center;
        gap: 10px;
        .MuiAutocomplete-root {
            width: 50%;
        }
    }
    .association__notes {
        .MuiTextField-root {
            .MuiInputBase-root {
                height: 92px;
            }
        }
    }
}

@media screen and (min-width: 800px) {
    .form__container {
        max-width: 400px;
        .form__data, .formassoc__location {
            width: 400px;
        }
    }
}
