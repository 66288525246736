@import "../../App.scss";
.seldetail__container {
    background-color: $extra-background-color;
    .detail__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .detail__name {
            text-align: center;
            margin: $non-margin;
            font-size: 28px;
            font-family: $secondary-font;
        }
        img {
            width: 280px;
            object-fit: cover;
            border-radius: 0 0 10px 10px;
        }
        .detail__info {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: $secondary-font;
            padding: 0 $md-padding;
            padding-top: 0;
            font-size: $lg-font-size;
            font-weight: 800;
            .small__text {
                font-family: $extra-font;
                font-size: $xs-font-size;
                font-weight: 400;
                margin: 0;
            }
            .sales__price {
                font-size: $md-font-size;
                margin: 0;
                margin-bottom: $xxs-margin;
            }
            .total__price {
                margin: 0;
                margin-bottom: $xxs-margin;
            }
            button {
                margin: 0;
            }
            .detail__button {
                width: 100%;
                margin-bottom: $xxs-margin;
            }
            .detail__shipment {
                padding-bottom: $xxs-padding;
            }
            .detail__description {
                text-align: center;
                font-family: $secondary-font;
                font-size: $s-font-size;
                margin-bottom: $xs-margin;
                max-width: $xxl-width;
                p {
                    text-align: left;
                }
            }
        }
        .MuiDivider-root {
            width: $xxl-width;
            border-color: rgba(120, 120, 120, 0.5);
            margin-bottom: $xs-margin;
        }
    }
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    background-color: $base-background-color;
    .checkout__resume {
        background-color: $base-background-color;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 10px;
        padding-bottom: 30px;
        img {
            width: $xs-width;
        }
        .detail__product {
            display: flex;
            flex-direction: column;
            justify-content: start;
            gap: 10px;
            font-family: $extra-font;
            font-size: $xs-font-size;
            h3 {
                font-family: $secondary-font;
                font-weight: 700;
                //font-size: $md-font-size;
            }
            .detail__pack {
                min-height: $xxs-heigth;
            }
            .detail__price {
                font-family: $extra-font;
                font-style: italic;
                margin: 0;
                line-height: 5px;
            }
            p {
                margin: 0;
            }
        }
        .detail__total {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: $xxl-width;
            .total__price {
                font-weight: 700;
                font-family: $secondary-font;
            }
            img {
                width: 30px;
            }
        }
    }
    .seldetail__container {
        .resume__container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $base-background-color;
            .resume__name {
                text-align: center;
                margin: $non-margin;
                font-size: 20px;
                font-family: $secondary-font;
            }
            img {
                width: 180px;
                object-fit: cover;
                border-radius: 0 0 10px 10px;
                margin: 10px 0;
            }
            .resume__info {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: $secondary-font;
                padding: 0 $md-padding;
                padding-top: 0;
                font-size: $md-font-size;
                font-weight: 800;
                .small__text {
                    font-family: $extra-font;
                    font-size: $xs-font-size;
                    font-weight: 400;
                    margin: 0;
                }
                .sales__price {
                    font-size: $s-font-size;
                    margin: 0;
                    margin-bottom: $xxs-margin;
                }
                .total__price {
                    margin: 0;
                    margin-bottom: $xxs-margin;
                }
                .resume__description {
                    text-align: center;
                    font-family: $secondary-font;
                    font-size: $s-font-size;
                    margin-bottom: $xs-margin;
                    max-width: $xxl-width;
                    .resume__pack {
                        margin: 0;
                        font-size: $xs-font-size;
                    }
                }
            }
            .MuiDivider-root {
                width: $xxl-width;
                border-color: rgba(120, 120, 120, 0.5);
                margin-bottom: $xs-margin;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    .seldetail__container {
        .detail__container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: $md-padding;
            .breadcrumb__container {
                padding: 0;
                margin-bottom: $xs-margin;
                p {
                    margin: 0;
                }
            }
            .detail__img {
                img {
                    border: 1px solid $gray-border-color;
                    padding: $xxs-width $xs-width;
                    border-radius: 0;
                }
                .detail__description {
                    max-width: 500px;
                    h3 {
                        text-align: center;
                        font-family: $secondary-font;
                    }
                    p {
                        font-family: $extra-font;
                    }
                }
            }
            .detail__info {
                align-self: start;
                align-items: start;
                width: 50%;
                max-width: 420px;
                .detail__name {
                    margin-bottom: $lg-margin;
                }
                .sales__price {
                    margin: 0;
                }
                .detail__pack {
                    font-family: $extra-font;
                    font-size: 16px;
                    font-weight: 400;
                    margin-top: $xl-margin;
                }
                .total__price {
                    margin-top: 120px;
                }
                .detail__button {
                    margin: $xl-margin 0 $xl-margin 0;
                }
                .detail__shipment {
                    padding-bottom: $xxxs-padding;
                    max-width: 380px;
                    align-self: center;
                }
                .MuiDivider-root {
                    margin-bottom: $xxxs-padding;
                    width: 380px;
                }
            }
        }
    }
}
