@import "../../App.scss";
.about__container {
    font-family: $secondary-font;
    img {
        height: $lg-width;
        background-size: cover;
    }
    .main__text {
        font-size: $xxl-font-size;
        font-weight: 800;
        font-style: italic;
        margin: 0;
        margin-top: $xs-margin;
        text-align: center;
    }
    .secondary__text {
        font-family: $extra-font;
        font-size: $md-font-size;
        font-weight: 500;
        margin: 0;
        text-align: center;
    }
    .about__img {
        display: flex;
        flex-direction: column;
        background-image: url($base-url + "/club.jpg");
        color: $base-text-color;
        align-items: center;
        justify-content: end;
        border-radius: 0 0 20px 20px;
        padding: $xxs-padding 0;
        height: $md-heigth;
        background-size: cover;
    }
    .about__guide {
        display: flex;
        flex-direction: column;
        transition: 0.5s;
        padding: $xs-padding;
        align-items: center;
        .main__text {
            margin-bottom: $xxs-margin;
        }
        .about_description {
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 30px;
            transition: 0.5s;
            padding: 0 20px;
            letter-spacing: 2px;
            font-size: $xs-font-size;
            font-weight: 600;
            width: 300px;
            svg {
                height: 14px;
            }
            &.nav__visible {
                height: 30vw;
            }
        }
        .about__detail {
            height: 0;
            margin: 0;
            transition: 0.5s;
            overflow: hidden;
            text-align: left;
            font-family: $extra-font;
            font-size: $xs-font-size;
            max-width: 300px;
            &.show__detail {
                height: $xxs-heigth;
                padding: 10px 0px;
            }
        }
        .MuiDivider-root {
            width: 300px;
            border-color: rgba(120, 120, 120, 0.5);
            margin: 5px 20px;
        }
    }
    .about__boxes {
        display: flex;
        flex-direction: column;
        .main__text {
            margin: 0;
            margin-bottom: $xxs-margin;
        }
        .secondary__text {
            margin-bottom: $xs-margin;
        }
    }
    .about__club {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            height: $lg-heigth;
            border-radius: 20px 20px 0 0;
            width: 100%;
            object-fit: cover;
        }
        .about__text__container {
            background-color: $base-background-color;
            border-radius: 0 0 20px 20px;
            width: 100%;
            display: flex;
            justify-content: center;
            .about__texts {
                color: $base-text-color;
                font-family: $secondary-font;
                font-weight: 800;
                font-style: italic;
                text-align: left;
                padding-top: 0;
                padding: $xxs-padding;
                .about__text {
                    font-size: $lg-font-size;
                    .about__info {
                        font-weight: 500;
                        font-size: $xs-font-size;
                        font-family: $extra-font;
                        margin-top: $s-margin;
                        :nth-of-type(1) {
                            line-height: 0;
                        }
                        :nth-of-type(2) {
                            font-style: normal;
                        }
                    }
                    .MuiDivider-root {
                        border-color: rgba(120, 120, 120, 0.5);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .about__container {
        .about__wines,
        .about__boxes {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 20px;
            cursor: pointer;
        }
    }
}

@media screen and (min-width: 900px) {
    .about__container {
        .about__club {
            .about__text__container {
                .about__texts {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    .about__text {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        max-width: $xl-width;
                        .MuiDivider-root {
                            border-bottom-width: 0;
                            border-right-width: thin;
                            align-self: stretch;
                            width: 0;
                        }
                    }
                }
            }
        }
    }
}
