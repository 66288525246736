@import "../../../App.scss";
.confirmation__container {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    text-align: center;
    margin: 40px 0 20px 0;
    h2 {
        margin-bottom: 40px;
    }
    .confirmation {
        h3 {
            text-align: center;
            font-family: $secondary-font;
            font-size: $lg-font-size;
            font-weight: 700;
        }
        .confirmation__info {
            font-style: normal;
            border: 1px solid $gray-text-color;
            border-radius: 5px;
            padding: 0 $xxxs-padding;
            margin: 0 $xxs-margin;
            max-width: $xxl-width;
            margin: 0 auto;
            h3 {
                font-style: normal;
                font-family: $extra-font;
                font-size: $xxs-font-size;
                font-weight: normal;
            }
            .confirmation__detail {
                display: flex;
                flex-direction: column;
                .confirmation__data {
                    font-size: $xxs-font-size;
                    margin-bottom: $xxs-margin;
                    p {
                        margin: 0;
                    }
                    .data__change {
                        display: flex;
                        justify-content: space-between;
                        font-weight: 800;
                        .data__button {
                            font-weight: 400;
                            text-decoration: underline;
                            color: $gray-text-color;
                            cursor: pointer;
                        }
                    }
                    .data__value {
                        color: $gray-text-color;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 800px) {
    .confirmation__container {
        width: 100%;
        max-width: 1000px;
    }
}
