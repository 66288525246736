.register__container {
    margin: 150px 0 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .password__error {
        color: red;
        text-align: center;
        margin-bottom: 10px;
        line-height: 0px;
    }
    button {
        margin: 0 auto;
        max-width: 300px;
    }
}
