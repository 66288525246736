$base-url: "https://unico.boutique";

/* Background colors */
$base-background-color: #151821;
$secondary-background-color: #fefefe;
$extra-background-color: #f1f1f1;
$extra-background-color2: #d9d9d9;

/* Text colors */
$base-text-color: #ffffff;
$secondary-text-color: #151821;
$extra-text-color: #290202;
$extra2-text-color: #49454f;
$black-text-color: #1e1e1e;
$gray-text-color: #787878;

/* Button colors */
$base-button-color: #151821;
$secondary-button-color: #290202;
$extra-button-color: rgba(21, 24, 33, 0.5);

/* Border colors */
$base-border-color: #000000;
$secondary-border-color: #fff;
$gray-border-color: #8e8e8f;
$blue-border-color: #1976d2;

/* Font sizes */
$xxs-font-size: 12px;
$xs-font-size: 14px;
$s-font-size: 18px;
$md-font-size: 20px;
$lg-font-size: 24px;
$xl-font-size: 30px;
$xxl-font-size: 40px;
$xxxl-font-size: 60px;

$base-font: "DM Serif Text";
$secondary-font: "TT Ramillas Trl";
$menu-font: "Inter";
$extra-font: "Raleway";

/* Padddings */
$non-padding: 0;
$xxxs-padding: 10px;
$xxs-padding: 22px;
$xs-padding: 24px;
$s-padding: 28px;
$md-padding: 32px;
$lg-padding: 40px;
$xl-padding: 50px;
$xxl-padding: 60px;

/* Margins */
$non-margin: 0;
$xxs-margin: 14px;
$xs-margin: 24px;
$s-margin: 28px;
$md-margin: 32px;
$lg-margin: 40px;
$xl-margin: 50px;
$xxl-margin: 60px;

$non-width: 0;
$xxs-width: 50px;
$xs-width: 80px;
$s-width: 120px;
$md-width: 160px;
$lg-width: 200px;
$xl-width: 240px;
$xxl-width: 360px;

$non-heigth: 0;
$xxs-heigth: 40px;
$xs-heigth: 80px;
$s-heigth: 130px;
$md-heigth: 200px;
$lg-heigth: 240px;
$xl-heigth: 340px;
$xxl-heigth: 500px;

$xxs-transition: 300ms;
$xs-transition: 600ms;
$s-transition: 900ms;
$md-transition: 300ms;
$lg-transition: 1500ms;
$xl-transition: 1800ms;
$xxl-transition: 2100ms;

button {
    background-color: $base-button-color;
    border-radius: 100px;
    height: $xxs-heigth;
    font-size: $xxs-font-size;
    font-weight: 400;
    line-height: 0;
    padding: 20px $s-padding;
    color: $base-text-color;
    margin: $xs-margin $non-margin;
    letter-spacing: 2px;
    border: none;
    cursor: pointer;
}

.MuiTextField-root {
    .MuiInputBase-root {
        height: 40px;
    }
    .MuiFormLabel-root {
        top: -7px;
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #86b7fe;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        border-width: 1px;
    }
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    #root {
        .container {
            margin: 90px 0 0 0;
        }
    }
}

@media screen and (min-width: 800px) {
    .container {
        margin: 0 120px;
    }
}
