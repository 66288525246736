@import "../../App.scss";
.club__container {
    .club__title {
        padding: 20px;
        font-family: $secondary-font;
        text-align: center;
        font-size: $xl-font-size;
        font-style: italic;
        font-weight: 800;
        margin-bottom: $non-margin;
    }
    .body__instructions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .body__instruction {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 400px;
            font-size: $s-font-size;
            margin-bottom: $xxs-margin;
            text-align: center;
            width: $s-width;
            font-family: $secondary-font;
            font-weight: 500;
            color: $secondary-text-color;
            p {
                margin: 0;
            }
        }
        .MuiDivider-root {
            margin-top: $xs-margin;
            width: $lg-width;
            border-style: dashed;
            border-color: $gray-border-color;
        }
    }
}

@media screen and (min-width: 600px) {
    .club__container {
        .body__instructions {
            flex-direction: row;
            align-items: center;
            gap: 80px;
            .MuiDivider-root {
                border-bottom-width: 0;
                border-right-width: thin;
                align-self: stretch;
                width: 0;
            }
        }
    }
}
