@import "../../App.scss";
.history__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $md-margin 0;
    .history__title {
        margin-bottom: $xs-margin;
    }
    .histories {
        display: flex;
        align-items: center;
        gap: 10px;
        .history__card {
            img {
                border: solid 1px $gray-text-color;
                width: $md-width;
                height: $lg-heigth;
                padding: $xxs-padding;
                object-fit: cover;
            }
            .history__month {
                border: solid 1px $gray-text-color;
                border-radius: 20px;
                margin-top: $xxxs-padding;
                padding: 6px;
            }
        }
    }
}
.without__history {
    width: $md-width;
    height: $lg-heigth;
}
