@import "../../App.scss";
.product__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: $xxl-width;      
        object-fit: cover;
        border-radius: 0 0 10px 10px;
    }
    .product__wrapper {
        padding: 0 $md-padding;
        padding-top: 0;
        .product__info {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: $md-padding;
            gap: 20px;
            font-family: $secondary-font;
            font-size: $lg-font-size;
            font-weight: 800;
            .product__selection {
                text-align: center;
                margin: $non-margin;
            }
            .small__text {
                font-family: $extra-font;
                font-size: $xs-font-size;
                font-weight: 400;
            }
            .sales__price {
                font-size: $md-font-size;
            }
            button {
                margin: 0;
                width: 100%;
            }
        }
    }
}
