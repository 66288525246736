@import "../../../App.scss";

.checkout__stepper {
    .MuiBox-root {
        gap: 0;
        #cardPaymentBrick_container {
            max-width: 300px;
            height: 640px;
            form {
                .form-inputs-1zFeeq {
                    .fade-wrapper-34hB2J {
                        .svelte-1jwum41 {
                            display: none;
                        }
                    }
                }
                .fade-wrapper-34hB2J {
                    .row-dOpyEt {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .row-dOpyEt.margin-top-3G8ko4.svelte-f1k4kg {
                        align-items: center;
                    }
                    
                }
            }
        }
        .MuiAlert-root {
            width: $xxl-width;
        }
    }
}

@media screen and (min-width: 800px) {
    .checkout__stepper {
        .MuiBox-root {
            #cardPaymentBrick_container {
                .container-2EtaVo,
                .form-inputs-1zFeeq {
                    max-width: 400px;
                }
            }
        }
    }
}
