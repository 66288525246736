@import "../../App.scss";
.benefits__wrapper {
    text-align: center;
    .benefits__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h3 {
            font-family: $secondary-font;
            font-size: $lg-font-size;
            font-weight: 200;
            font-style: normal;
            color: $secondary-text-color;
            line-height: 14px;
            margin-top: $lg-margin;
            span {
                font-weight: 800;
                font-style: italic;
                font-size: $xl-font-size;
            }
        }
        img {
            width: 100%;
            height: $md-width;
            object-fit: cover;
        }
        .benefits {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: $base-background-color;
            margin-bottom: $md-padding;
            width: 100%;
            border-radius: 0 0 20px 20px;
            .benefits_subtitle {
                font-family: $secondary-font;
                font-size: $xl-font-size;
                font-weight: 800;
                font-style: italic;
                color: $base-text-color;
            }
            .benefits__text {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                p {
                    font-weight: 400;
                    font-style: normal;
                    font-size: $xs-font-size;
                    color: $base-text-color;
                    letter-spacing: 1px;
                    line-height: 14px;
                    padding-left: $xs-padding;
                    &::before {
                        content: "\2713";
                        margin-right: 5px;
                        margin-bottom: 5px;
                    }
                }
            }
            button {
                border: 1px solid $base-text-color;
                font-family: $extra-font;
                margin-bottom: $lg-margin;
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .benefits__wrapper {
        .benefits__container {
            flex-direction: row;
            img {
                border-radius: 0 20px 0 0;
                height: $xxl-width;
                width: 50%;
            }
            .benefits {
                border-radius: 20px 0 0 0;
                margin-bottom: 0;
                height: $xxl-width;
            }
        }
    }
}
