@import "../../App.scss";
.container {
    .home__img {
        display: flex;
        flex-direction: column;
        background-image: url($base-url + "/home.jpg");
        background-size: cover;
        height: $xxl-width;
        color: $base-text-color;
        align-items: center;
        justify-content: end;
        border-radius: 0 0 20px 20px;
        .home__text {
            display: flex;
            flex-direction: column;
            align-items: center;
            .main__text {
                font-family: $secondary-font;
                font-style: italic;
                font-size: $xxl-font-size;
                font-weight: 800;
                line-height: $xxs-heigth;
                margin: $non-margin;
            }
            .secondary__text {
                font-family: $extra-font;
                font-style: normal;
                font-weight: normal;
                font-size: $lg-font-size;
                line-height: $xxs-heigth;
                margin: $non-margin;
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .container {
        .home__img {
            height: 800px;
            justify-content: center;
            padding-right: $xs-width;
            .home__text {
                align-self: flex-end;
            }
        }
    }
}
